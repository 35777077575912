<template>
        <top-card
          :detailPath="detailPath"
          :morePath="moreLink"
          :title="title"
          :list="list"
        >
          <template v-slot="scope">
            <div class="title-box">
                    <span class="leftspot">·</span>
                    <div class="title" > {{scope.row.item.title}}</div>
                    <!-- <div>
                      <span class='title-tag' v-for="(type, index) in scope.row.item.types" :key="index">
                        {{type.respTypes}} 
                      </span> 
                    </div> -->
                    <div>
                      <span class='title-tag' v-for="(type, index) in scope.row.item.types" :key="index">
                        {{type.respTechnology}}
                      </span>  
                    </div>
                    <div>
                      <span class='title-tag-info' v-for="(type, index) in scope.row.item.formats" :key="index">
                       {{type}}
                      </span> 
                    </div>
            </div>
          </template>
        </top-card>
</template>

<script>
import topCard from "@/components/list/complexTopCard"; //引入内容组件
export default {
  name: "DatasetTop",  
  components: {
    topCard, //引入内容组件
  },
  data() {
    return {
      moreLink: '/dataset',
      detailPath:'/dataset/detail',
      title:'数据集和模型',
      list: [],
    };
  },
  mounted() {
    this.getlist();
  },
  
  methods: {
    // 获取当前数组
    getlist() {
      var data = {
        curPage: 1,
        pageSize: 6,
      }
      
      this.$api.getDataset(data).then((res) => {
        this.list = [...res.data.dataList];
      });
      
    //   this.$api
    //     .getresource({
    //       curPage: 1,
    //       pageSize: 3,
    //     })
    //     .then((res) => {
    //       list = [...res.data.dataList];
    //       this.$api
    //         .getmodel({
    //           curPage: 1,
    //           pageSize: 3,
    //         })
    //         .then((result) => {
    //           list = [...result.data.dataList,...list];
    //            this.cardlist[0].newlist[1].list = list
    //         });
    //     });

    },
  },

};
</script>

<style scoped lang="less">

// div{
//      border: 2px solid #ee410c;
//      padding: 20px;
//   }
//   span{
//      border: 2px solid #19d869;
//      padding: 20px;
//   }

  
  .title-box{
        display: flex;
        align-items: center;
        .title{
          // border-bottom: 0.0625rem solid #ececec;
          // line-height: 38px;
        }

        .title {
          // font-size: 0.875rem;
        }
        .title:hover {
          color: #02a7f0;
          text-decoration: underline;
        }
        .title-tag-info{
          font-size: 12px;
          background-color: #f4f4f5;
          border-color: hsl(240, 5%, 92%);
          color: #909399;
          border-radius: 0.25rem;
          padding: 0 0.2rem;
          margin-left: 1rem;
          line-height: 1.25rem;
          height: 1.25rem;
        }
        .title-tag {
          font-size: 12px;
          color: #0063ff;
          background-color: #fff;
          border-color: #99c1ff;
          border-width: 1px;
          border-style: solid;
          border-radius: 0.25rem;
          margin-left: 1rem;
          // height: 2rem;
          padding: 0 0.2rem;
          line-height: 1.25rem;
          height: 1.25rem;
          display: inline-block;
          box-sizing: border-box;
          white-space: nowrap;
        }
      }


@media screen and (min-width: 1000px) {
}

@media screen and (max-width: 999px) {
  .pagesbox{
    margin-bottom: 1.25rem;
  }
  .title-box{
    .title-tag-info{
      display:none;
    }
  }
}
</style>
