<template>
  <div class="box">
    <card :title="title" :ismore="true" @morelink="moreLink">
      <div class="notext" v-if="list.length == 0">暂无数据</div>
      <div class="erhid"
        @click="detailLink(item.id)"
        v-for="(item, index) in list"
        :key="index"
      >
        <slot v-bind:row="{item:item,index:index}">
          <span class="leftspot">·</span>
          <span class="title" >{{ item.title || item.name}}</span >
        </slot>
      </div>
    </card>
  </div>
</template>

<script>
import card from "@/components/all/card"; //封装的卡片样式
export default {
  name: "newcard", //首页新闻展示
  components: {
    card,
  },
  props: {
    // 内容的数组
    list: Array,
    // 卡片标题
    title: String,
    // 详情路径
    detailPath: String,
    // more路径
    morePath: String,
  },
   
  data() {
    return {
    }
  },
  mounted() {},
  methods: {
      // 点击更多进行跳转
    moreLink() {
      // console.log(" more link ", this.morePath)
      //  进行路由跳转
      this.until.jump.call(this, this.morePath, "push");
    },
    // 进行详情的跳转
    detailLink(id) {
      if( !this.detailPath) {
        console.error("-------- do not config " +  this.title +" detail path:" + this.detailPath)
        return 
      }

      var path = this.detailPath+"/"+id;
      console.log(" detail link ", path)
      //  进行路由跳转
      this.until.jump.call(this, path, "push");
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1000px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .notext{
      font-size: .875rem;
      margin: .3125rem;
    }
    /deep/ .cardcent {
      height: 13.125rem;
    }
    /deep/.el-icon-d-arrow-right {
      display: none;
    }
    .leftspot {
      margin-right: 0.3125rem;
      font-size: 0.875rem;
    }
    .erhid{
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*禁止换行*/
      text-overflow: ellipsis; /*省略号*/
    }
    .title {
      font-size: 0.875rem;
      
    }
    .title:hover {
      color: #02a7f0;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 999px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .notext{
      font-size: .875rem;
      margin: .3125rem;
    }
    /deep/.el-icon-d-arrow-right {
      display: none;
    }
    .leftspot {
      margin-right: 0.3125rem;
      font-size: 0.875rem;
    }
    .erhid{
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*禁止换行*/
      text-overflow: ellipsis; /*省略号*/
    }
    .title {
      font-size: 0.875rem;
      
    }
    .title:hover {
      color: #02a7f0;
      text-decoration: underline;
    }
  }
}
</style>
