<template>
  <div>
     <homebox  />
  </div>
 

</template>

<script>

import homebox from "./homebox"; //引入内容组件


export default {
  data() {
    return {
      primary: 0, //一级菜单的选中样式
      istrue: true, //刷新页面
    };
  },
  components: {

    homebox,//引入内容组件

  },
  created() {},
  mounted() {},
  methods: {
    
  },
  // 页面注销切换滚动条高度
  destroyed(){
    document.documentElement.scrollTop = 0
  }
};
</script>

<style scoped lang="less">

</style>
