<template>
  <!-- 卡片样式 -->
  <div class="cardbox">
    <!-- 卡片的头部 -->
    <div class="flexspb cardhead">
      <!-- 左边的线 -->
      <div class="leftline" />
      <!-- 卡片标题 -->
      <div class="title">{{ title }}</div>
      <!-- 右边的更多文字按钮 -->
      <div v-if="ismore" class="more" >
          <router-link :to="{path: morelink}" >  
        <span >更多</span>
        <i class="el-icon-d-arrow-right"></i>
        </router-link>
      </div>
    </div>
    <!-- 内容 -->
    <div class="cardcent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "card", //卡片
  props: {
    // 卡片标题
    title: String,
    // 右边的更多按钮
    ismore: Boolean,
    morelink: String,
  },
  methods: {
    // // 点击更多时触发
    // morelink() {
    //   // 传出点击的item
    //   this.$emit("morelink");
    // },
  },
};
</script>


<style scoped lang="less">
 .cardbox {
    width: 100%;
    height: 100%;
    padding: 10px 0.9375rem 0.9375rem;
    box-sizing: border-box;
    background-color: #FFF;
 
    // 卡片的头部
    .cardhead {
      height: 2.5rem;
      padding-right: 0.4375rem;
      box-sizing: border-box;
      border-bottom: 0.0625rem solid #dbdbdb;
      // 左边的线
      .leftline {
        width: 0.125rem;
        height: 1.25rem;
        margin-right: 0.625rem;
        background-color: #16499b;;
        border: 0.0625rem solid #16499b;
      }
      // 卡片标题
      .title {
        flex: 1;
        color: #444;
        font-size: 20px;
        font-weight: 550;
      }
      // 更多
      .more {
        color: #999;
        font-size: .875rem;
      }
      // 更多（鼠标移入）
      .more:hover {
        color: #88b3eb;
      }
    }
    // 卡片内容
    .cardcent {
      box-sizing: border-box;
      line-height: 2rem;
      letter-spacing: 0.0625rem;
    }
  }
  .cardbox:hover {
    cursor: pointer;
    // background-color: #f8f8f8;
  }

@media screen and (max-width: 999px) {
  // 当前页面宽高
}

@media screen and (min-width: 1000px) {
  // 当前页面宽高
 
}
</style>
