<template>
  <div>
   
    <div class="linebox flexspb">
      <div class="halfbox">
        <news-carousel/>        
      </div>
      <div class="halfbox">
        <news-top news-tag='0' />
      </div>
    </div>


    <div class="imglinebox flexspb">
      <div class="onebox">
        <tool-top-image/>       
     </div>
    </div>
  

    <!-- <div class="linebox flexspb">
      <div class="halfbox">
        <tool-top/>       
     </div>
      <div class="halfbox">
        <inspect-top/>       
     </div>
    </div> -->
    <div class="linebox flexspb">
       <div class="halfbox">
        <inspect-top/>       
     </div>
      <div class="halfbox">
        <dataset-top/>     
     </div>
    </div>
  
    <!-- <div class="linebox  <dataset-top/> flexspb">
      <div class="onebox">
        <dataset-top/>     
      </div>
    </div> -->

    <div class="linebox flexspb" >
      <div class="halfbox">
        <standard-top />
      </div>
      <div class="halfbox">
        <patent-top />
      </div>
    </div >

    <div class="linebox flexspb" >
      <div class="halfbox">
        <case-top />
      </div>
      <div class="halfbox">
        <analyse-top />
      </div>
    </div >

    <div class="linebox flexspb" >
      <div class="halfbox">
        <consult-top />
      </div>
      <div class="halfbox">
          <train-top />
      </div>
    </div>
    <!-- <div class="linebox flexspb">
      <div class="halfbox">
        <compliance-top/>     
      </div>
      <div class="halfbox">
        <showcase-top/>       
      </div> 
    </div> -->
    
    <!-- <div class="linebox flexspb" >
      <div class="halfbox">
        <info-top />
      </div>
    </div> -->
    <!-- <div class="linebox flexspb" >
      <partner/>
    </div> -->
  </div>
</template>

<script>
// import showcaseTop from "@/views/show/caseTop";  
// import complianceTop from "@/views/show/complianceTop";
 
import datasetTop from "@/views/dataset/datasetTop"; 
import toolTopImage from "@/views/tool/toolTopImage"; 
import inspectTop from "@/views/industry/industryTop"; 
import newsCarousel from "@/views/news/comp/newsCarousel";  
import newsTop from "@/views/news/comp/newsTop";  
import standardTop from "@/views/standard/standardTop";  
import patentTop from "@/views/patent/patentTop";  
import caseTop from "@/views/case/caseTop";  
import analyseTop from "@/views/analyse/analyseTop" 
import trainTop from "@/views/train/trainTop";  
import consultTop from "@/views/consult/consultTop";

// import infoTop from "@/views/basicinfo/infoTop";  

// import partner from './partner';

export default {
  name: "homebox", //首页页面
   components: {
    // showcaseTop,
    // complianceTop,
    datasetTop,
    toolTopImage,
    // toolTop,
    inspectTop,
    newsCarousel,
    newsTop, //引入新闻盒子组件
    standardTop,
    patentTop,
    caseTop,
    analyseTop,
    trainTop,
    consultTop,
    // infoTop,
    // partner,
  },
  data() {
    return { 
      d: false,
    }
  },
};
</script>

<style scoped lang="less">

  // div{
  //   border: 1px solid #f0f;
  // }
  // 每一行盒子的内容
  .imglinebox{
    // width: 100%;
    // margin-top: 1rem;
    // height: 200px;
    .onebox {
      width: 100%;
      background-color: #FFF;
      // display: inline-flex;
      // width: 100%;
      // height: 100%;
      // flex: 1;
      // box-sizing: border-box;
      margin: 0.5rem 0;
      // align-items:  stretch;
      // border: 1px solid #0ff;
    }
  }
  .linebox {
    // margin-top: 1rem;
    // height: 280px;
    // border: 1px solid #f0f;
    .halfbox {
      width: 50%;
      height: 280px;
      flex: 1;
      box-sizing: border-box;;
      // border: 1px solid #0ff;
    }
    .halfbox:nth-child(1) {
      // padding: 0.5rem 0.5rem 0.5rem 0;
      display: inline-flex;
      align-items:  stretch;
      padding: 10px 10px 10px 0;
    }
    .halfbox:nth-child(2) {
      padding: 10px 0 10px 10px;
    }
    .onebox {
      // background-color: #FFF;
      display: inline-flex;
      width: 100%;
      // height: 100%;
      flex: 1;
      box-sizing: border-box;
      padding: 0.5rem 0;
      align-items:  stretch;
      // border: 1px solid #0ff;
    }
  }

// // 第四行的高度
//   .linebox:nth-child(4) {
//     height: 14.375rem;
//   }
//   // 第五行的高度
//   .linebox:nth-child(5) {
//     height: 16.875rem;
//   }
  
  // 第一行的高度
  // .linebox:nth-child(1) {
  //   height: 16.25rem;
  //   // 轮播图
  //   .banner {
  //     padding: 0 0.3125rem;
  //     height: 100%;
  //     flex: 1;
  //     box-sizing: border-box;
  //   }
  //   // 右边的菜单
  //   .rightnar {
  //     height: 100%;
  //     width: 26.25rem;
  //   }
  // }
  
@media screen and (min-width: 1000px) { }

// @media screen and (max-width: 999px) {
//   // 每一行盒子的内容
//   .linebox {
//     margin-top: 1.25rem;
//     flex-wrap: wrap;
//     .halfbox {
//       width: 100%;
//       box-sizing: border-box;
//       padding: 0 0.9375rem;
//       margin-top: 1.25rem;
//     }
//     .halfbox:first-child {
//       margin: 0;
//     }
//   }
//   // 第一行的高度
//   .linebox:nth-child(1) {
//     // 轮播图
//     .banner {
//       padding: 0 0.9375rem;
//       height: 12.5rem;
//       flex: 1;
//       box-sizing: border-box;
//     }
//     // 右边的菜单
//     .rightnar {
//       margin-top: 1.25rem;
//     }
//   }
// }
</style>
