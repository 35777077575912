<template>
        <top-card
          :detailPath="detailPath"
          :morePath="moreLink"
          :title="title"
          :list="list"
        />
</template>

<script>
import topCard from "@/components/list/topCard"; //引入内容组件
export default {
  name: "AnalyseTop", //信息公告页面
  components: {
    topCard, //引入内容组件
  },
  props: {
    // 当前的页码)
    newsTag: {
      typeof: 1,
      // require: true,
    },
  },
  data() {
    return {
      moreLink: '/resource/analyse',
      detailPath: '/resource/analyse/detail',
      title:'产业分析报告',
      list: [],
    };
  },
  mounted() {
    this.getlist();
  },
  
  methods: {
    // 获取当前数组
    getlist() {
      // console.log("  news tag " + this.newsTag    )
      this.$api.getAnalyseTop().then((data) => {
        // console.log("------------ getNewsTop -----------", data)
        if(data.code ==0 || data.code == 200 ){
          this.list = data.data;
        }       
      });     
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1000px) {
  
}

@media screen and (max-width: 999px) {
  .pagesbox{
    margin-bottom: 1.25rem;
  }
}
</style>
