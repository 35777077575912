<template>

    <card :title="title" :ismore="true" :morelink="moreLink"> 
  
      <div class="img-panel flexH">
        <div class="item-panel flexfvc"
          v-for="(item, index) in list"
          :key="index"
        >    

         <router-link :to="{path: detailPath+'/'+item.id}" target="_blank">     
            <div class="img-item" >
              <img :src="item.img" class="img" />
            </div > 
            <div class="tool-title">
                {{item.name}} 
            </div>
        </router-link>

           
        </div>
      </div> 
    </card>
    
</template>

<script>
import card from "@/components/all/cardBg"; //封装的卡片样式
// import topCard from "@/components/list/topCard"; //引入内容组件
export default {
  name: "PatentTop", //信息公告页面
  components: {
    card,
    // topCard, //引入内容组件
  },
  props: {
    // 当前的页码)
    newsTag: {
      typeof: String,
      // require: true,
    },
  },
  data() {
    return {
      moreLink: '/inspect/tool',
      detailPath: '/inspect/tool/detail',
      title:'在线检测工具',
      list: [],
      // list: [ 1,2,3,4,5,6],
    };
  },
  mounted() {
    this.getlist();
  },
  
  methods: {
    // 获取当前数组
    getlist() {
      // console.log("  news tag " + this.newsTag    )
      this.$api.getToolTop().then((data) => {
        // console.log("------------ getNewsTop -----------", data)
        if(data.code ==0 || data.code == 200 ){
          this.list = data.data;
        }       
      });     
    },
  },
};
</script>

<style scoped lang="less">

 

.img-panel {
  // width: 100%;
  // border: 1px solid #00f;
  // padding: 10px;
  .item-panel {
    //  float:left;
    // margin: 0 5px;
    // padding: 10px;
    // border:1px solid #ddd;
    // border: 1px solid #f00;
    overflow:hidden; 

    .tool-title {
        width:185px;
        font-size: 16px;
        // white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        // padding-bottom: 10px;
     }
  

    .img-item {
      // border-radius:15px;
      // border: 1px solid #0f0;
      // width:224px;
      // height:130px;
      overflow:hidden;


      img:hover{
        transform:scale(1.3)
      }
      img {
        width:185px;
        height:140px;
        transition:all .6s;
      }
      .img-panel {
        padding: 10px;
      }
    }
  }
}

</style>
