<template>
  <!-- 轮播图盒子 -->
  <div class="bannerbox">
    <!-- 轮播图 -->
    <el-carousel class="bannerbox">
      <!-- 轮播内容 -->
      <el-carousel-item v-for="item in bannerlist" :key="item.id">
        <router-link :to="{path: '/news/detail/'+item.id}" target="_self"> 
          <!-- <div @click="imglink(item.id)"> -->
            <!-- <div class="title">{{ item.title }} {{item.fileUrl}}</div> -->
            <img :src="item.fileUrl" style="height:100%;width:100%;" alt="item.title" :title="item.title"/>
            <!-- <div v-html="item.newsContent" class="ql-editor"></div> -->
          <!-- </div>  -->
        </router-link>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "newsCarousel", //轮播图页面
 
  data() {
    return {
      bannerlist: [],
    };
  },
  created() {
    console.log("  carsouel created ")
    this.getList()
  },
  methods: {
    // 点击图片时触发
    imglink(id) {
      console.log("  id " , id)
    //   // 首页/信息公告/平台公告/详情/?title=&id=405
    //   //  进行路由跳转
    //   this.until.jump.call(this, "/news/detail/"+id, "push");
    },

       // 获取轮播图列表
    getList() {
      console.log("  news carsouel created ")
   
      this.$api.getCarouselList().then((res) => {
      // // this.$api.getNewsList(obj).then((res) => {
        console.log("  get data res", res)
        this.bannerlist = res.data

         console.log(" get banner ", this.bannerlist)
      });
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1000px) {
  // 当前页面宽高
  .bannerbox {
    width: 100%;
    height: 100%;
    // 轮播图
    /deep/ .el-carousel__container {
      width: 100%;
      height: 100%;
    }
    // 切换剪头
    /deep/.el-carousel__arrow{
      width: 2.25rem;
      height: 2.25rem;
      margin: 0 .625rem;
      i{
        font-size: 1.5rem;
      }
    }
    // 指示器
    /deep/ .el-carousel__indicator {
      padding: 0.75rem 0.25rem 1rem;
      .el-carousel__button {
        width: 2rem;
        height: 0.1875rem;
        background-color: #ebecee;
        border-radius: 0.1875rem;
      }
    }
    /deep/ .is-active {
      .el-carousel__button {
        background-color: #acacac;
      }
    }
    // 标题内容
    .title {
      margin: 0.625rem 0;
      text-align: center;
      color: #02a7f0;
      font-weight: 550;
      font-size: 0.875rem;
    }
    // 富文本内容
    .ql-editor {
      height: 13.75rem;
      line-height: 1.8;
      box-sizing: border-box;
      padding: 0 .9375rem 0.9375rem;
      overflow: hidden;
    }
    /deep/ p {
      font-size: 0.875rem;
    }
  }
  .bannerbox:hover {
    cursor: pointer;
    background-color: #f8f8f8;
    border-radius: 0.3125rem;
  }
}

@media screen and (max-width: 999px) {
 // 当前页面宽高
  .bannerbox {
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    // 轮播图
    /deep/ .el-carousel__container {
      width: 100%;
      height: 100%;
    }
    // 切换剪头
    /deep/.el-carousel__arrow{
     display: none;
    }
    // 指示器
    /deep/ .el-carousel__indicator {
      padding: 0.75rem 0.25rem 1rem;
      .el-carousel__button {
        width: 2rem;
        height: 0.1875rem;
        background-color: #ebecee;
        border-radius: 0.1875rem;
      }
    }
    /deep/ .is-active {
      .el-carousel__button {
        background-color: #acacac;
      }
    }
    // 标题内容
    .title {
      margin: 0.625rem ;
      text-align: center;
      color: #02a7f0;
      font-weight: 550;
      font-size: 0.875rem;
    }
    // 富文本内容
    .ql-editor {
      height: 8.75rem;
      line-height: 1.8;
      box-sizing: border-box;
      padding: 0 .9375rem 0.9375rem;
      overflow: hidden;
    }
    /deep/ p {
      font-size: 0.875rem;
    }
  }
}
</style>
